<template>
  <div class="login-main">
    <div class="login-left">
      <img src="@/static/img/logo.png"
           class="login-logo"
           @click="toIndex" />
      <img src="@/static/img/login_left_bg.png"
           srcset
           class="login-left-bg" />
      <div class="login-left-main"
           style>
        <div style="font-size:30px;color:#333;font-weight:700;">
          陌陌联营后台
          <span class="login-period">·</span>
          商家版
        </div>
        <div style="height:92px;display:flex;flex-direction:column;justify-content: space-between;">
          <div>真正打破信任危机</div>
          <div>主播管理轻松</div>
          <div>小团队快速成长</div>
        </div>
        <div style="height:1px;background:#e3e3e3"></div>
        <div>
          <div class="login-left-main-card">
            <div>高级用户享用版</div>
            <div style="font-size:14px;">功能流畅 简洁大方 快捷入口 效率翻倍</div>
          </div>
          <div style="font-size:12px;margin-top:79px;">豫ICP备19039737号 中能魔力(河南)网络科技有限公司</div>
        </div>
      </div>
    </div>
    <div class="login-right">
      <router-view />
      <img src="@/static/img/login_right_bg.png"
           alt
           srcset
           class="login-right-bg" />
    </div>
  </div>
</template>

<script>
export default {
  name: "login",
  components: {},
  data () {
    return {
      current: "login",
    };
  },
  mounted () { },
  methods: {
    toIndex () {
      window.location.href = "http://www.wanghongtoutiao.cn/";
    },
  },
  created () {
    const userInfo = this.$getStorage("userInfo");
    const autoLogin = this.$getStorage("autoLogin");
    if (userInfo && autoLogin) {
      if (this.role(["staff"])) {
        this.$router.push({ path: "/anchor/anchorList" });
      } else {
        this.$router.push({ path: "/home" });
      }
    }
    if (window.location.pathname !== '/') {
      window.location.href = window.location.origin
    }
  },
};
</script>

<style scoped lang="less">
.login-main {
  height: 100vh;
  min-height: 550px;
  display: flex;
  min-width: 1080px;
  position: relative;
  .login-left {
    width: 68%;
    background: #f9f9f9;
    height: 100vh;
    .login-logo {
      width: 184px;
      position: absolute;
      left: 7%;
      top: 1.85vh;
      cursor: pointer;
    }
    .login-left-bg {
      width: 70%;
    }
    .login-left-main {
      width: 300px;
      height: 410px;
      position: absolute;
      left: 18.06%;
      top: 0px;
      bottom: 0px;
      margin: auto;
      color: #666;
      font-size: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .login-left-main-card {
        background-image: url(../../static/img/login_card_bg1.png);
        background-size: 100% auto;
        width: 290px;
        height: 100px;
        color: #fff;
        padding: 28px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
  .login-right {
    width: 32%;
    // background: linear-gradient(270deg, #ad5fea 0%, #f269ff 100%);
    background: linear-gradient(270deg, #619dff 0%, #3278eb 100%);
    height: 100vh;
    position: relative;
    .login-right-bg {
      width: 100%;
      position: absolute;
      bottom: 0px;
    }
  }
}
</style>
