<template>
  <div class="leftMenu">
    <el-menu :router="true"
             :default-active="defaultPath"
             class="el-menu-vertical-demo"
             :unique-opened="true"
             :collapse="isCollapse"
             @select="menuSelect"
             text-color="#666666">
      <!-- 单个menu -->
      <el-menu-item index="/home"
                    v-role="['administrator','broker','personal','manager']">
        <i class="icon iconfont icon-shouye"></i>
        <span>首页</span>
      </el-menu-item>
      <!-- 多个menu -->
      <el-submenu index="2"
                  v-role="['administrator']">
        <template slot="title">
          <i class="icon iconfont icon-houtaishezhi"></i>
          <span slot="title">后台管理</span>
        </template>
        <el-menu-item index="/backstage/guildpage">
          <span slot="title"
                class="secondMenu">公会管理</span>
        </el-menu-item>
        <el-menu-item index="/backstage/importdata">
          <span slot="title"
                class="secondMenu">数据导入</span>
        </el-menu-item>
        <el-menu-item index="/backstage/noticepage">
          <span slot="title"
                class="secondMenu">公告管理</span>
        </el-menu-item>
      </el-submenu>
      <!-- 经纪人管理 -->
      <el-submenu index="3"
                  v-role="['administrator','broker','personal','manager']">
        <template slot="title">
          <i class="icon iconfont icon-jingjirenliebiao"></i>
          <span slot="title">经纪人管理</span>
        </template>
        <el-menu-item index="/agentmanage/agentlist"
                      v-role="['administrator','broker','personal','manager']">
          <span slot="title"
                class="secondMenu">经纪人列表</span>
        </el-menu-item>
        <el-menu-item index="/agentmanage/check"
                      v-role="['administrator','broker','personal','manager']">
          <span slot="title"
                class="secondMenu">审核管理</span>
        </el-menu-item>
      </el-submenu>
      <!-- 员工列表 -->
      <!-- <el-menu-item index="/stafflist"
                    v-role="['administrator','broker','personal']">
        <i class="icon iconfont icon-yuangongliebiao"></i>
        <span>员工列表</span>
      </el-menu-item> -->
      <!-- 主播管理 -->
      <el-submenu index="4">
        <template slot="title">
          <i class="icon iconfont icon-zhuboguanli"></i>
          <span slot="title">主播管理</span>
        </template>
        <el-menu-item index="/anchor/anchorList">
          <span slot="title"
                class="secondMenu">主播列表</span>
        </el-menu-item>
        <el-menu-item index="/anchor/liveDataLsit">
          <span slot="title"
                class="secondMenu">直播数据</span>
        </el-menu-item>
        <el-menu-item index="/anchor/enrollmentList">
          <span slot="title"
                class="secondMenu">主播入会审批</span>
        </el-menu-item>
        <el-menu-item index="/anchor/anchorMonitor">
          <span slot="title"
                class="secondMenu">直播监控</span>
        </el-menu-item>
      </el-submenu>
      <!-- 财务 -->
      <el-submenu index="5"
                  v-role="['administrator','broker','personal','manager']">
        <template slot="title">
          <i class="icon iconfont icon-caiwu"></i>
          <span slot="title">财务</span>
        </template>
        <el-menu-item index="/finance/operation">
          <span slot="title"
                class="secondMenu">经营分析</span>
        </el-menu-item>
        <el-menu-item index="/finance/liveincome">
          <span slot="title"
                class="secondMenu">直播收入</span>
        </el-menu-item>
        <el-menu-item index="/finance/withdraw">
          <span slot="title"
                class="secondMenu">提现管理</span>
        </el-menu-item>
        <el-menu-item index="/finance/acwithdraw">
          <span slot="title"
                class="secondMenu">主播提现管理</span>
        </el-menu-item>
      </el-submenu>
      <!-- 个人中心 -->
      <el-menu-item index="/personalpage"
                    v-role="['administrator','broker','personal','manager']">
        <i class="icon iconfont icon-gerenzhongxin"></i>
        <span>个人中心</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      defaultPath: '/home'
    };
  },
  created () {
    localStorage.setItem('indexPath', this.$route.path)
    const indexPath = localStorage.getItem('indexPath')
    this.defaultPath = indexPath ? indexPath : '/home'
  },
  methods: {
    menuSelect (index) {
      localStorage.setItem('indexPath', index)
    },
  },
  watch: {
    $route (to) {
      this.defaultPath = to.path
    },
    pathList: {
      handler: function (val) {
        if (val.length) {
          this.defaultPath = val[0].path
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState({
      pathList: (state) => state.layouts.pathList,
    }),
  }
}
</script>

<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 260px;
}
.icon {
  color: #666666;
  vertical-align: -2px;
}
span {
  margin-left: 6px;
}
.leftMenu /deep/ .el-menu-item .secondMenu {
  margin-left: 1px;
}
.leftMenu /deep/ .el-submenu__icon-arrow {
  margin-top: -5px;
}
.leftMenu /deep/ .el-menu-item.is-active {
  color: #ffffff;
  background: #3278eb;
}
.leftMenu
  /deep/
  ul.el-menu-vertical-demo.el-menu--collapse.el-menu
  /deep/
  li.el-submenu.is-active {
  color: #ffffff;
  background: #3278eb;
}
.leftMenu
  /deep/
  ul.el-menu-vertical-demo.el-menu--collapse.el-menu
  /deep/
  li.el-submenu.is-active
  /deep/
  i.icon.iconfont {
  color: #fff;
}
</style>