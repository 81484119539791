/* 
 * @Author: 曹俊杰 
 * @Date: 2020-08-26 12:53:17
 * @Module: 主播列表api
 */
import request from '@/utils/request'
export default {
	//  主播列表
	anchorListApi (params) {
		return request.post(`api/anchor/list`, params)
	},
	// 删除主播
	anchorDelete (parmas) {
		return request.post(`api/anchor/delete/${parmas.anchor_id}`)
	},
	// 主播详情信息
	anchorDetail (parmas) {
		return request.post(`api/anchor/detail/${parmas.anchor_id}`)
	},
	// 主播详情的列表和图表数据
	anchorDetailData (parmas) {
		return request.post(`api/data/detail/${parmas.anchor_id}`, parmas)
	},
	// 添加主播
	anchorCreate (parmas) {
		return request.post(`api/anchor/create`, parmas)
	},
	// 修改主播
	anchorUpdate (parmas) {
		return request.post(`api/anchor/update/${parmas.anchor_id}`, parmas)
	},
	// 邀请主播对公结算
	anchorInviteToPublic (parmas) {
		return request.post(`api/anchor/invite-to-public`, parmas)
	},
	// 设置分成比和手机号
	anchorSetInfo (parmas) {
		return request.post(`api/anchor/set-info`, parmas)
	},
	// 修改主播签约信息（陌陌）
	updateContractInfo (parmas) {
		return request.post(`api/anchor/update-contract-info/${parmas.anchor_id}`, parmas)
	}
}
